/* eslint-disable */
export type Maybe<T> = T | null;

/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: Date;
};


export enum RequiredPermission {
  ReadData = 'READ_DATA',
  ReadConfig = 'READ_CONFIG',
  WriteConfig = 'WRITE_CONFIG',
  ReadSettings = 'READ_SETTINGS',
  WriteSettings = 'WRITE_SETTINGS',
  CrossTenantAdmin = 'CROSS_TENANT_ADMIN'
}


export type Query = {
  __typename?: 'Query';
  getUserPreferences: UserPreferences;
  getThermostatSettings: Array<ThermostatSettings>;
  getThermostatSetting: ThermostatSettings;
  getThermostatConfigurations: Array<ThermostatConfiguration>;
  getThermostatConfiguration: ThermostatConfiguration;
  getSensorConfigurations: Array<SensorConfiguration>;
  getSensorConfiguration: SensorConfiguration;
  getLatestThermostatValues: Array<ThermostatValue>;
  getLatestThermostatValue: ThermostatValue;
  getLatestSensorValues: Array<SensorValue>;
  getLatestSensorValue: SensorValue;
  getThermostatValueStreams: Array<ThermostatValueStream>;
  getSensorValueStreams: Array<SensorValueStream>;
};


export type QueryGetThermostatSettingArgs = {
  id: Scalars['ID'];
};


export type QueryGetThermostatConfigurationArgs = {
  id: Scalars['ID'];
};


export type QueryGetSensorConfigurationArgs = {
  id: Scalars['ID'];
};


export type QueryGetLatestThermostatValueArgs = {
  id: Scalars['ID'];
};


export type QueryGetLatestSensorValueArgs = {
  id: Scalars['ID'];
};


export type QueryGetThermostatValueStreamsArgs = {
  streamName: Scalars['String'];
  fromDate?: Maybe<Scalars['DateTime']>;
  toDate?: Maybe<Scalars['DateTime']>;
};


export type QueryGetSensorValueStreamsArgs = {
  streamName: Scalars['String'];
  fromDate?: Maybe<Scalars['DateTime']>;
  toDate?: Maybe<Scalars['DateTime']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  updateUserPreferences: UserPreferences;
  createThermostatSettings: ThermostatSettings;
  updateThermostatSettings: ThermostatSettings;
  createThermostatConfiguration: ThermostatConfiguration;
  updateThermostatConfiguration: ThermostatConfiguration;
  createSensorConfiguration: SensorConfiguration;
  updateSensorConfiguration: SensorConfiguration;
};


export type MutationUpdateUserPreferencesArgs = {
  userPreferences: UserPreferencesUpdateInput;
};


export type MutationCreateThermostatSettingsArgs = {
  thermostatSettings: ThermostatSettingsCreateInput;
};


export type MutationUpdateThermostatSettingsArgs = {
  thermostatSettings: ThermostatSettingsUpdateInput;
};


export type MutationCreateThermostatConfigurationArgs = {
  thermostatConfiguration: ThermostatConfigurationCreateInput;
};


export type MutationUpdateThermostatConfigurationArgs = {
  thermostatConfiguration: ThermostatConfigurationUpdateInput;
};


export type MutationCreateSensorConfigurationArgs = {
  sensorConfiguration: SensorConfigurationCreateInput;
};


export type MutationUpdateSensorConfigurationArgs = {
  sensorConfiguration: SensorConfigurationUpdateInput;
};

export enum TemperatureUnits {
  Celsius = 'Celsius',
  Fahrenheit = 'Fahrenheit'
}

export type UserPreferences = {
  __typename?: 'UserPreferences';
  temperatureUnits: TemperatureUnits;
};

export type UserPreferencesUpdateInput = {
  temperatureUnits?: Maybe<TemperatureUnits>;
};

export enum ThermostatAction {
  Heat = 'Heat',
  Cool = 'Cool',
  Circulate = 'Circulate'
}

export enum ThermostatSettingType {
  Hold = 'Hold',
  Scheduled = 'Scheduled'
}

export enum DayOfWeek {
  Monday = 'Monday',
  Tuesday = 'Tuesday',
  Wednesday = 'Wednesday',
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
  Sunday = 'Sunday'
}

export type ThermostatSetting = {
  __typename?: 'ThermostatSetting';
  type: ThermostatSettingType;
  holdUntil?: Maybe<Scalars['DateTime']>;
  daysOfWeek?: Maybe<Array<DayOfWeek>>;
  atMinutesSinceMidnight?: Maybe<Scalars['Int']>;
  allowedActions: Array<ThermostatAction>;
  setPointHeat: Scalars['Float'];
  setPointCool: Scalars['Float'];
  setPointCirculateAbove: Scalars['Float'];
  setPointCirculateBelow: Scalars['Float'];
};

export type ThermostatSettingCreateInput = {
  type: ThermostatSettingType;
  holdUntil?: Maybe<Scalars['DateTime']>;
  daysOfWeek?: Maybe<Array<DayOfWeek>>;
  atMinutesSinceMidnight?: Maybe<Scalars['Int']>;
  allowedActions: Array<ThermostatAction>;
  setPointHeat: Scalars['Float'];
  setPointCool: Scalars['Float'];
  setPointCirculateAbove: Scalars['Float'];
  setPointCirculateBelow: Scalars['Float'];
};

export type ThermostatSettingUpdateInput = {
  type: ThermostatSettingType;
  holdUntil?: Maybe<Scalars['DateTime']>;
  daysOfWeek?: Maybe<Array<DayOfWeek>>;
  atMinutesSinceMidnight?: Maybe<Scalars['Int']>;
  allowedActions: Array<ThermostatAction>;
  setPointHeat: Scalars['Float'];
  setPointCool: Scalars['Float'];
  setPointCirculateAbove: Scalars['Float'];
  setPointCirculateBelow: Scalars['Float'];
};

export type ThermostatSettings = {
  __typename?: 'ThermostatSettings';
  tenant: Scalars['String'];
  id: Scalars['ID'];
  settings: Array<ThermostatSetting>;
};

export type ThermostatSettingsCreateInput = {
  id: Scalars['ID'];
  settings: Array<ThermostatSettingCreateInput>;
};

export type ThermostatSettingsUpdateInput = {
  id: Scalars['ID'];
  settings: Array<ThermostatSettingUpdateInput>;
};

export type ThermostatConfigurationCreateInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  streamName: Scalars['String'];
  availableActions: Array<ThermostatAction>;
  externalSensorId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  threshold: Scalars['Float'];
  cadence: Scalars['Int'];
};

export type ThermostatConfigurationUpdateInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  streamName?: Maybe<Scalars['String']>;
  availableActions: Array<ThermostatAction>;
  externalSensorId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  threshold?: Maybe<Scalars['Float']>;
  cadence?: Maybe<Scalars['Int']>;
};

export type ThermostatConfiguration = {
  __typename?: 'ThermostatConfiguration';
  tenant: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  streamName: Scalars['String'];
  availableActions: Array<ThermostatAction>;
  externalSensorId?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  threshold: Scalars['Float'];
  cadence: Scalars['Int'];
};

export type SensorConfigurationCreateInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  streamName: Scalars['String'];
};

export type SensorConfigurationUpdateInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  streamName?: Maybe<Scalars['String']>;
};

export type SensorConfiguration = {
  __typename?: 'SensorConfiguration';
  tenant: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  streamName: Scalars['String'];
};

export type ThermostatValue = {
  __typename?: 'ThermostatValue';
  id: Scalars['ID'];
  tenant: Scalars['String'];
  publishedTime: Scalars['DateTime'];
  deviceTime: Scalars['DateTime'];
  deviceLocalSerial: Scalars['Int'];
  temperature: Scalars['Float'];
  secondaryTemperature?: Maybe<Scalars['Float']>;
  humidity: Scalars['Float'];
  currentActions: Array<ThermostatAction>;
  setPointHeat: Scalars['Float'];
  setPointCool: Scalars['Float'];
  setPointCirculateAbove: Scalars['Float'];
  setPointCirculateBelow: Scalars['Float'];
  threshold: Scalars['Float'];
  allowedActions: Array<ThermostatAction>;
  currentTimezoneUTCOffset?: Maybe<Scalars['Float']>;
};

export type SensorValue = {
  __typename?: 'SensorValue';
  id: Scalars['ID'];
  tenant: Scalars['String'];
  publishedTime: Scalars['DateTime'];
  deviceTime: Scalars['DateTime'];
  deviceLocalSerial: Scalars['Int'];
  temperature: Scalars['Float'];
};

export type ThermostatValueStream = {
  __typename?: 'ThermostatValueStream';
  streamName: Scalars['String'];
  publishedTime: Scalars['DateTime'];
  deviceTime: Scalars['DateTime'];
  deviceLocalSerial: Scalars['Int'];
  temperature: Scalars['Float'];
  secondaryTemperature?: Maybe<Scalars['Float']>;
  humidity: Scalars['Float'];
  currentActions: Array<ThermostatAction>;
  setPointHeat: Scalars['Float'];
  setPointCool: Scalars['Float'];
  setPointCirculateAbove?: Maybe<Scalars['Float']>;
  setPointCirculateBelow?: Maybe<Scalars['Float']>;
  threshold: Scalars['Float'];
  allowedActions: Array<ThermostatAction>;
  currentTimezoneUTCOffset?: Maybe<Scalars['Float']>;
};

export type SensorValueStream = {
  __typename?: 'SensorValueStream';
  streamName: Scalars['String'];
  publishedTime: Scalars['DateTime'];
  deviceTime: Scalars['DateTime'];
  deviceLocalSerial: Scalars['Int'];
  temperature: Scalars['Float'];
};

export type PlotSeriesQueryVariables = {
  streamName: Scalars['String'];
  fromDate: Scalars['DateTime'];
  toDate: Scalars['DateTime'];
};


export type PlotSeriesQuery = (
  { __typename?: 'Query' }
  & { getThermostatValueStreams: Array<(
    { __typename?: 'ThermostatValueStream' }
    & Pick<ThermostatValueStream, 'deviceTime' | 'temperature'>
  )> }
);
